import React from 'react';
import HeaderCallAction from './headerCallAction'
import Footer from './footer'
import Helmet from 'react-helmet'
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled'

const LayoutMessagePage = (props) => {
    return (
        <React.Fragment>
            <Global
                styles={css`
                    html {
                        font-size: 50%;
                        box-sizing:border-box;
                        scroll-behavior: smooth;
                        @media(min-width:768px){
                            font-size: 62.5%;
                        }
                        @media(min-width:992px){
                            font-size: 70%;
                        }
                        @media(min-width:1200px){
                            font-size: 75%;
                        }
                    }
                    *,*:before, *:after{
                        box-sizing:inherit;
                    }
                    body {
                        font-size: 18px;
                        font-size: 1.6rem;
                        line-height: 1.5;
                        font-family:'Montserrat', sans-serif;
                        background-color:#FFF;
                        color:#164688;
                        margin:0;
                        overflow-x: hidden;
                    }
                    ::-webkit-scrollbar {
                        width: 10px;
                        background-color: none;
                    }

                    ::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background: #0024B6;
                    }
                    @-moz-document url-prefix() {
                        .scroller {
                            scrollbar-color: #0024B6 #0024B6;
                            scrollbar-width: thin;
                        }
                    }
                    h1,h2,h3{
                        margin:0;
                        line-height:1.5;
                    }
                    p{
                        font-size:2.3rem;
                        @media(min-width:1200px){
                            font-size: 1.8rem;
                        }
                    }
                    .slick-next{
                        right: 25px !important;
                        z-index:10000
                    }
                     .slick-prev{
                        left: 25px !important;
                        z-index:10000
                    }
                    .slick-next:before, .slick-prev:before {
                        color: #FFBE85 !important;
                    }
                    footer img{
                        max-width:400px!important;
                        height:auto!important;
                    }

                    .slick-slide div div:focus{
                        outline:none !important;
                    }
                    `}
            />
            <Helmet>
                <title>Rayodent</title>
                <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.css" as="style" />
                <link rel="preload" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" as="style" />
                <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                <body className="scroller" />
            </Helmet>
            <HeaderCallAction />
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQFCV43"
                height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
            {props.children}
            <Footer />
        </React.Fragment>
    );
}

export default LayoutMessagePage